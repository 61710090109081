

#basicTwoCol {
    --barbie: #e0218a;
    --blue: #2563eb;
    --brick: #570000;
    --gold: #ca8a04;
    --gray: #4f4f4f;
    --green: #0d9488;
    --ocean: #0891b2;
    --steel-bg: #f1f3f3;
    --steel-color: black; 
    --default-font-color: gray;
    --section-label-font-size: 16px;
    --name-font-size: 1.6em;
    --label-font-size: 1.4em;
}

.barbie #basicTwoCol .first-column{
    background-color: var(--barbie);
    color: white !important;
}

.blue #basicTwoCol .first-column {
    background-color: var(--blue);
    color: white;
}

.gold #basicTwoCol .first-column {
    background-color: var(--gold);
}

.brick #basicTwoCol .first-column {
    background-color: var(--brick);
    color: white;
}

.gray #basicTwoCol .first-column {
    background-color: var(--gray);
    color: white;
}

.green #basicTwoCol .first-column {
    background-color: var(--green);
    color: white;
}

.steel #basicTwoCol .first-column,
.steel #basicTwoCol .progress .bar,
.steel #basicTwoCol .first-column #name,
.steel #basicTwoCol .first-column #label,
.steel #basicTwoCol .first-column .header,
.steel #basicTwoCol .first-column .ui.header,
.steel #basicTwoCol .first-column .sub.header,
.steel #basicTwoCol .first-column .meta,
.steel #basicTwoCol .first-column i.icon,
.steel #basicTwoCol .first-column a  {
    background-color: var(--steel-bg);
    color: var(--steel-color);
}

.ocean #basicTwoCol .first-column {
    background-color: var(--ocean);
    color: white;
}

/* Set default white color for these items */
#basicTwoCol .first-column i.icon,
#basicTwoCol .first-column a,
 #basicTwoCol .first-column .sectionLabel,
 #basicTwoCol .first-column .header,
 #basicTwoCol .first-column .sub.header,
 #basicTwoCol .first-column .item .meta {
    color: white;
}

#basicTwoCol .first-column h3 {
    font-size: 14px;
}

/* lines */
.lines #basicTwoCol h2  {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 5px;
  
}
.lines #basicTwoCol .bottom .sectionWrapper {
    margin-top: 20px;
}

.lines #basicTwoCol .bottom #Work.sectionWrapper {
    border-top-style: none;
}

/* .lines #basicTwoCol .first-column .sectionLabel {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 5px;
} */


#basicTwoCol .first-column .ui.progress{
    margin: 5px 0 ;
}
#basicTwoCol .second-column .segment {
    padding: 10px 15px;
}

#basicTwoCol .first-column {
    background-color: #fafafa;
    padding: 20px;
}

#basicTwoCol .first-column .ui.items>.item {
    margin: 0;
}

#basicTwoCol .first-column .ui.items>.item.tagListContainer {
    margin: 0 0 10px 0;
}
#basicTwoCol .first-column .ui.items>.item>.content>.description {
    margin: 0;
}

#basicTwoCol .second-column {
    padding-top: 20px;
}

#basicTwoCol .segment.sectionWrapper {
    margin-top: 40px;
    margin-bottom: 40px;
}

#basicTwoCol .userImage {
    width: 120px;
    height: 120px;
    margin: auto;
    margin-top: 20px;
    border-radius: 50%;
}

#basicTwoCol .center {
    text-align: center;
    align-items: center;
}

#basicTwoCol #name {
    font-size: var(--name-font-size);
    display: block;
    margin-top: 40px;
    text-align: center;
}

#basicTwoCol #label {
    font-size: var(--label-font-size);
    display: block;
   font-weight: 500;
    text-align: center;
    padding: 0;
    margin: 0px 0 40px 0;
    background-color: unset;
    border: none;
}

#basicTwoCol .first-column .ui.items>.item .meta,
#basicTwoCol .first-column .ui.items>.item>.content>.first-columner {
    margin: 0;
}

#basicTwoCol .first-column div#website,
#basicTwoCol .first-column div#Profiles,
#basicTwoCol .first-column div#contact {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

#basicTwoCol .first-column .sectionLabel {
    font-size: var(--section-label-font-size);
    font-weight: bold;
    text-transform: uppercase;
}

#basicTwoCol .first-column .ui.items>.item {
    padding: 5px 0;
}

#basicTwoCol .second-column .ui.bottom.attached.segment {
    border: none;
}

#basicTwoCol .second-column a {
    color: unset;
} 

#basicTwoCol .resumebody div#References  h3{
    text-transform: initial;
}

/* #basicTwoCol .first-column #Education .schoolName,
#basicTwoCol .first-column #Languages .languageName .content,
#basicTwoCol .first-column #Interests .interestsName {
    font-size: var(--school-name-font-size);
    font-weight: bold;
}

#basicTwoCol .first-column #Education .schoolArea {
    font-size: var(--school-area-font-size);
} */

#basicTwoCol #website.sectionWrapper {
    margin: 0;
}

#basicTwoCol #contact.sectionWrapper {
    margin-bottom: 0;
}


#basicTwoCol #Work.sectionWrapper,
#basicTwoCol #Projects.sectionWrapper {
    margin-top: 20px;
}

#basicTwoCol #Profiles .sectionLabel {
    display: none;
}

#basicTwoCol .first-column .ui.items {
  margin: .5em 0; 
}