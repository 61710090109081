.App {
  text-align: center;
}

body{
  font-family: Lato,sans-serif;
  font-size: 16px;
  
}

/* Sigh, reeally we need to tweak this */
.ui.items>.item>.content>.description {
  font-size: 1.1em;
}

/* reset segment */
.ui.segment {
  position: unset;
  background: unset;
  box-shadow: unset;
  margin: unset;
  padding: unset;
  border-radius: unset;
  border: unset;
}

.ui.progress .bar {
  height: 0.75em;
  margin: 5px 0;
}
.App-header {
  background-color: #222;
  height: 200px;
}

.ui.top.attached.header{
  width: inherit;
}

.well{
  background: #f4f6f6!important;
  padding: 40px 0;
  margin-bottom: 30px;
}

.no_bg{
  background: none!important;
}

.no_border{
  border: 0px!important;
}

.positionLabel{
  color: #95a5a6;
  font-size: 24px!important;
}

.basicsName{
  border-bottom: 2px solid #5f9fde!important;
  /*border-bottom: 1px solid #7b8896!important;*/
  color: rgba(35, 42, 62, 0.72)!important;
}


.ui.items>.item .extra {
  color: #4f4c4c;
}
.ui.list .list>.item, .ui.list>.item, ol.ui.list li, ul.ui.list li {
  display: inline-table;
}
.ui.list .list>.item, ol.ui.list ol li, ul.ui.list ul li {
  margin-bottom: 7px;
}

.tagItem{
  margin: 3px!important;
}

/* Common Shared options for both layouts */
.serifheader  #name,
.serifheader  .header,
.serifheader h1,
.serifheader h2,
.serifheader h3,
.serifheader h4,
.serifheader h5{
  font-family: EB Garamond !important;
}
.sansheader  #name{
  font-family:  Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}

body .serifbody { 
  font-family: EB Garamond;
}
body .sansbody {
  font-family:  Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important; 
}
.certificates  #Certificates, 
.awards   #Awards, 
.languages   #Languages,  
.volunteer  #Volunteer,  
.publications  #Publications,  
.interests   #Interests,  
.references  #References{
  display: none;
}

.ui.label {
  margin: 5px 3px;
}

.resumebody h3.ui.header {
  font-size: 18px;
  text-transform: uppercase;
}

.resumebody h3 .sub.header {
  text-transform: initial;
}

.resumebody .ui div.extra > div > div > .list > .item {
 
  display: table;
}
.ui.items>.item {
  margin-bottom: 40px;
}
.ui.sub.header {
  font-size: 1em};