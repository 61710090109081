@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

#basicOneCol {
    --barbie: #e0218a;
    --blue: #2563eb;
    --brick: #570000;
    --gold: #ca8a04;
    --gray: #4f4f4f;
    --green: #0d9488;
    --ocean: #0891b2;
    --steel-bg: #f1f3f3;
    --steel-color: rgb(0 0 0 / 60%);
    --default-font-color: #434e5e;
    --contact-font-size: 15px;
    --section-label-font-size: 18px;
    --name-font-size: 44px;
    --label-font-size: 25px;
}

.barbie #basicOneCol .head,
.barbie #basicOneCol .progress .bar {
    background-color: var(--barbie);
}

.blue #basicOneCol .head,
.blue #basicOneCol .progress .bar {
    background-color: var(--blue);
}

.gold #basicOneCol .head,
.gold #basicOneCol .progress .bar {
    background-color: var(--gold);
}

.brick #basicOneCol .head,
.brick #basicOneCol .progress .bar {
    background-color: var(--brick);
}

.gray #basicOneCol .head,
.gray #basicOneCol .progress .bar {
    background-color: var(--gray);
}

.green #basicOneCol .head,
.green #basicOneCol .progress .bar {
    background-color: var(--green);
}

.steel #basicOneCol .head,
.steel #basicOneCol #name,
.steel #basicOneCol .progress .bar,
.steel #basicOneCol .head i.icon,
.steel #basicOneCol a {
    background-color: var(--steel-bg);
    color: var(--steel-color);
}

.ocean #basicOneCol .head,
.ocean #basicOneCol .progress .bar {
    background-color: var(--ocean);
}

/* .lines #basicOneCol .bottom .sectionWrapper {
    border-top: 1px solid #d4d4d4;
    margin-top: 20px;
} */

.lines #basicOneCol .bottom .sectionWrapper .sectionLabel {
    border-bottom: 1px solid #d4d4d4;
    margin-top: 20px;
    margin-bottom: 20px;
}

.lines #basicOneCol .bottom #Work.sectionWrapper {
    border-top-style: none;
}

body #basicOneCol {
    font-size: 16px;
    color: var(--default-font-color);
}

.ui.container #basicOneCol {
    max-width: 1000px;
}

#basicOneCol {
    margin-top: 50px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

#basicOneCol .hide-image {

    margin: 25px 0 15px 35px;
}

#basicOneCol .head a {
    background: none;
    color: rgb(255 255 255 / 60%);
    text-decoration: none;
}

#basicOneCol .head i.icon {
    color: #fff;
}

#basicOneCol div#About,
#basicOneCol .resumebody .ui.segment {
    padding: 20px 40px;
}

/* Don't double pad */
#basicOneCol .resumebody .ui.segment .segment{
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ui.attached.tabular.menu {
    margin: 0 30px;
    width: auto;
}

#basicOneCol .head {
    background-color: var(--gray);
    color: rgb(255 255 255 / 60%);
    margin-bottom: 20px;
}

#basicOneCol .head .row {
    padding: 0;
}

#basicOneCol .head img.userImage {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

#basicOneCol .head #contact-info {
    padding-top: 15px;
    font-size: var(--contact-font-size);
    line-height: 1.4;
    margin-bottom: 20px;
}

#basicOneCol .contact-columns {
    column-count: 2;
    column-gap: 20px;
}

#basicOneCol .sectionLabel {
    font-size: var(--section-label-font-size);
    color: var(--default-font-color);
    font-weight: 700;
    margin-bottom: 6px;
    margin-top: 15px;
    text-transform: uppercase;
}

#basicOneCol #name {
    color: #fff;
    font-family: 'EB Garamond', serif;
    font-size: var(--name-font-size);
    font-weight: 400;
    letter-spacing: .175rem;
    line-height: 100%;
    display: block;
    text-transform: uppercase;
    margin: 5px 0 0 0;
}

#basicOneCol #label {
    font-size: var(--label-font-size);
    display: block;
    padding: 0;
    margin: 10px 0 0 0;
    background-color: unset;
    color: #fff;
}

/* resumebody */



#basicOneCol .resumebody div#Certificates .ui.items>.item,
#basicOneCol .resumebody div#Languages .ui.items>.item,
#basicOneCol .resumebody div#Interests .ui.items>.item
{
    margin: 0 0 .7em;
}

#basicOneCol .resumebody h2 {
    font-size: 20px;
}

#basicOneCol .resumebody h3 {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

/* Don't uppercase */
#basicOneCol .resumebody h3 .sub.header ,
#basicOneCol .resumebody div#Certificates  h3,
#basicOneCol .resumebody div#Languages  h3,
#basicOneCol .resumebody div#References  h3,
#basicOneCol .resumebody div#Education  h3,
#basicOneCol .resumebody div#Interests  h3
 {
    text-transform: revert;
}

#basicOneCol .resumebody h4 {
    font-size: 16px;
}

#basicOneCol .resumebody a {
    color: unset;
} 

#basicOneCol .resumebody .ui.items {
    margin: .5em 0;
}

#basicOneCol .resumebody .ui.progress{
    margin: 0 ;
}

#basicOneCol .resumebody .ui.items .meta,
#basicOneCol .resumebody .ui.items .description{
    margin: 0.3em 0;
}


#basicOneCol #Profiles .sectionLabel {
    display: none;
}

.ui.bottom.segment.tab {
    padding: 0;
}

@media only screen and (max-width: 992px) {
    #basicOneCol .head .row .info {
        padding-left: 30px;
    }
}

@media only screen and (max-width: 700px) {
    #basicOneCol .contact-columns {
        column-count: 1;
        column-gap: 20px;
    }
}