.projectHeader {
    position: relative;
    display: inline-block;
  }
  
i.chainIcon  {
    visibility: hidden;
   
    margin-left: 5px;
    color: #a9a9a9;
    font-size: 20px;
    cursor: pointer;
  }

.projectHeader:hover .chainIcon,
.projectHeader i.chainIcon:hover {
    visibility:visible;
}

/* I am not sure why this is under .ui.items, but... */
.ui.items>.item>.content .description p {
  margin: .5em 0 1em;
}
#Projects .ui.items>.item {
  margin: 0 0 4em;
}
#basicOneCol .resumebody #Projects h3 {
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 20px;
}